<template>
  <div>
    <div id="data-list-list-view" class="data-list-container mt-2 px-">
      <!-- Table Top -->
      <b-row class="mb-2">
        <!-- Per Page -->
        <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-2 mb-md-2">
          <label>{{ $t('Labels.Show') }}</label>
          <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getResponse"/>
          <label>{{ $t('Labels.Registers') }}</label>
        </b-col>
        <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-md-0">
          <label for="example-datepicker">Desde</label>&nbsp;
          <b-form-datepicker id="beginDate" placeholder="" v-model="beginDate" class="mb-2"></b-form-datepicker>
        </b-col>
        <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-md-0">
          <label for="example-datepicker">Hasta</label>&nbsp;
          <b-form-datepicker id="finishDate" placeholder="" v-model="finishDate" class="mb-2"></b-form-datepicker>
        </b-col>
        <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-2">
          <b-button variant="primary" @click="getResponse">
            <feather-icon icon="SearchIcon" class="mr-50"/>
            <span>Buscar</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table hover :fields="fields" :items="response" selectable select-mode="single" @row-selected="goToDetail" :responsive="true" tbody-tr-class="shadow-sm" table-variant="light">
            <template #cell(type)="item">
              <listItem
                :value="(item.value == 'A' ? $t('Messages.Alert') : item.value == 'E' ? $t('Messages.Energy') : item.value == 'C' ? $t('Messages.Connection') : $t('Messages.Other'))"
                :description="$t('Labels.Type')"
                img="panicbutton"
              />
            </template>
            <template #cell(created_at)="item">
              <listItem
                :value="(item.value ? formDate(item.value, 'dayJs') : '' )"
                :description="$t('Labels.Date')"
                img="fecha"
              />
            </template>
            <template #cell(user)="item">
              <listItem
                :value="(item.value ? item.value.first_name + ' ' + item.value.last_name : '')"
                :description="(item.value ? $t('Labels.DisabledBy') : '')"
                :img="(item.value ? 'contact' : null)"
              />
            </template>
            <template #cell(disabled_at)="item">
              <listItem
                :value="(item.value ? formDate(item.value, 'dayJs') : '' )"
                :description="(item.value ? $t('Labels.Date') : '')"
                :img="(item.value ? 'fecha' : null)"
              />
            </template>
          </b-table>
          <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-class last-class class="mb-0 mt-1 mt-sm-0">
                <template #first-text>
                  <feather-icon icon="ChevronsLeftIcon" size="18"/>
                </template>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
                <template #last-text>
                  <feather-icon icon="ChevronsRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
    </div>
    <b-modal id="tracing-modal-confirm" title="Agregar Notas" hide-footer size="lg">
      <b-form-textarea
            v-model="observations"
            id="textarea-rows"
            :placeholder="$t('Labels.Notes')"
            rows="6"
          />
      <div class="text-right">
          <b-button variant="primary" @click="AddNotesPanic()" class="mt-2" :disabled="idPanicButton === null">
            {{$t('Labels.Accept')}}
          </b-button>
          <b-button variant="danger" @click="$bvModal.hide('tracing-modal-confirm')" class="mt-2 ml-1">
            {{$t('Messages.Cancel')}}
          </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import vSelect from 'vue-select'
import {
  BFormTextarea,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BFormDatepicker
} from 'bootstrap-vue'
import listItem from '@core/spore-components/listItem/listItem.vue'
import { timeZoneDate } from '@/libs/tools/helpers'

const DATA = {}

export default {
  components: {
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    listItem,
    vSelect,
    BTable,
    BPagination,
    BFormDatepicker
  },
  data () {
    return {
      response: [],
      uuid: this.$route.params.uuid,
      DATA,
      idTracker: null,
      trackers: [],
      idGroup: null,
      observations: '',
      idPanicButton: null,
      fields: [
        { key: 'type', label: this.$t('Labels.Type'), sortable: true },
        { key: 'created_at', label: this.$t('Labels.AlertedOn'), sortable: true },
        { key: 'disabled_at', label: this.$t('Labels.DisabledOn'), sortable: true },
        { key: 'user', label: this.$t('Labels.DisabledBy'), sortable: true }
      ],
      totalRows: 0,
      currentPage: 1,
      count: 0,
      perPageOptions: [20, 40, 60, 80, 100],
      beginDate: '',
      finishDate: '',
      filters: {
        limit: 20,
        offset: 0,
        byColumn: 'created_at',
        byOrder: 'DESC',
        type: '',
        is_disabled: '',
        begin_date: '',
        end_date: '',
        timezone_offset: ''
      }
    }
  },

  methods: {
    formDate (date, type) {
      return timeZoneDate(date, type)
    },
    goToDetail (data) {
      this.$router.push(`/panic_button/sm/detail/${this.uuid}/history/${data[0].uuid}`)
    },
    async getResponse () {
      if (this.beginDate) {
        this.filters.begin_date = this.beginDate + ' 00:00:00'
        this.filters.timezone_offset = (new Date().getTimezoneOffset())
      }
      if (this.finishDate) {
        this.filters.end_date = this.finishDate + ' 23:59:59'
        this.filters.timezone_offset = (new Date().getTimezoneOffset())
      }
      const params = {
        url: `panic_button/${this.uuid}/alerts`,
        method: 'GET',
        params: this.filters
      }
      await request(params).then(response => {
        this.response = response.data.data.rows
        this.totalRows = response.data.data.count
        this.count = response.data.data.rows.length
      }).catch(() => {
        this.response = []
      })
    },
    confirmAccept (uuid) {
      this.idGroup = uuid
      this.$bvModal.show('modal-confirm')
    },
    confirmAcceptNotes (uuid) {
      this.idPanicButton = uuid
      this.$bvModal.show('tracing-modal-confirm')
    },
    async AddNotesPanic () {
      this.$bvModal.hide('tracing-modal-confirm')
      const params = {
        url: `panic_button/${this.idPanicButton}/addNotes`,
        method: 'PUT',
        customMessages: true,
        params: {
          observations: this.observations
        }
      }
      await request(params).then(response => {
        this.getResponse()
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.RequestAccepted'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
        this.observations = ''
        this.idPanicButton = null
      }).catch(error => {
        if (error.response.data.code === 409) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.AlreadyExist'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        }
      })
    },
    confirmExit (uuid) {
      this.$swal({
        title: this.$t('Messages.DeclineGroup'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_groups/tracker_group_user/${uuid}`,
            method: 'DELETE',
            customMessages: true
          }
          await request(params).then(response => {
            this.$swal({
              title: this.$t('Messages.Success'),
              text: this.$t('Messages.RequestDeclined'),
              timer: 5000,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            })
            this.getResponse()
          })
        }
      })
    },
    confirmExitConvoy (uuid) {
      this.$swal({
        title: this.$t('Messages.DeclineGroup'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracing/tracing_tracker/invitation/${uuid}`,
            method: 'DELETE',
            customMessages: true
          }
          await request(params).then(response => {
            this.$swal({
              title: this.$t('Messages.Success'),
              text: this.$t('Messages.RequestDeclined'),
              timer: 5000,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            })
            this.getResponse()
          })
        }
      })
    }
  },
  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/ListGroups.scss";
</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
